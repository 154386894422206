.dark-gray {
  color: $dark-grey;
}
.link {
  color: $blue;
  cursor: pointer;
  &.disabled {
    color: $grey;
    cursor: not-allowed;
  }
  &:hover {
    text-decoration: underline;
  }
}
.button {
  display: inline-block;
  font-weight: $font-weight-bold;
  background-color: transparent;
  padding: 13px 40px;
  border-width: 2px;
  border-style: solid;
  cursor: pointer;
  text-align: center;
  border-radius: 100px;
  transition: all 0.2s ease-in-out;
  outline: none;
  font-size: 14px;
  &:hover {
    text-decoration: none;
  }

  &.btn-passive-light {
    color: $white;
    border-color: $white;
    &:hover {
      color: #000;
      background-color: $white;
      border-color: $white;
    }
  }
  &.btn-passive-wireframe {
    color: #005f9e;
    border: 1px solid #005f9e;
    &:hover {
      color: $white;
      background-color: #005f9e;
    }
  }
  &.btn-active {
    color: $white;
    border-color: $blue;
    background-color: $blue;
    &:hover {
      background-color: $dark-blue;
      border-color: $dark-blue;
    }
    &:active,
    &:focus {
      box-shadow: 0 0 1px $black;
    }
    &.btn-danger {
      border-color: $red;
      background-color: $red;
      &:hover {
        border-color: $dark-red;
        background-color: $dark-red;
      }
      &:active {
        border-color: $darker-red;
        background-color: $darker-red;
      }
    }
  }
  &.btn-wide {
    padding: 13px 55px;
  }
}

#link-nav-back,
.link-nav-back {
  &:hover {
    text-decoration: none;
    .text {
      text-decoration: underline;
    }
  }
  .text {
    margin-left: 2px;
  }
}
.clear:after {
  position: relative;
  content: '';
  display: block;
  clear: both;
}
ul.error {
  list-style: none;
  padding: 0;
  li {
    padding-bottom: 1em;
  }
}
.error {
  color: $red !important;
  border-color: $red;
}
.password-input-error {
  margin-top: 5px;
  .description {
    font-size: 11px;
    font-weight: 700;
    padding-bottom: 4px;
  }
  .error-messages {
    p {
      color: $light-green;
      padding: 0;
      display: inline-block;
      font-size: 11px;
      vertical-align: middle;
    }
    .icon {
      width: 12px;
      height: 12px;
      margin-right: 3px;
    }
    .error-message-negative {
      p {
        color: $red;
      }
      .icon {
        mask-image: url('../images/ic_close.svg');
        background-color: $red;
      }
    }
    .error-message-positive {
      .icon {
        mask-image: url('../images/ic_check.svg');
        background-color: $light-green;
      }
    }
    .error-message-neutral {
      p {
        color: $dark-grey;
      }
      .icon {
        mask-image: url('../images/ic_dot.svg');
        background-color: $dark-grey;
      }
    }
  }
}
.hr {
  width: 80%;
  height: 1px;
  background-color: $grey;
  margin-left: auto;
  margin-right: auto;

  &.hr-light {
    background-color: $light-grey;
    width: 100%;
  }
}

.divider {
  display: block;
  position: relative;
  line-height: 1px;
  margin: auto;
  box-sizing: border-box;
  border-radius: 100%;
  text-align: center;
  @include top-offset(5);
  @include bottom-offset(5);
  &:before,
  &:after {
    content: '';
    position: absolute;
    height: 1px;
    display: block;
    zoom: 1;
    -ms-filter: 'progid:DXImageTransform.Microsoft.gradient(startColorstr=#21000000, endColorstr=#21000000)';
    background-color: #eee;
  }
  &:before {
    left: 0;
    right: 55%;
  }
  &:after {
    right: 0;
    left: 55%;
  }
}
a {
  color: $blue;
  text-decoration: none;
  outline: none;
  &:hover {
    text-decoration: underline;
  }
}
input {
  display: block;
  width: 100%;
  height: 56px;
  padding: 17px;
  font-size: 16px;
  line-height: 1.5;
  color: #000;
  border: 1px solid $light-grey;
  border-radius: 4px;
  background: $light-grey;
  outline: none;
  box-sizing: border-box;
  &:hover,
  &:focus {
    border-color: $blue !important;
    &:disabled {
      border-color: $light-grey !important;
    }
  }
}
p {
  padding-bottom: 14px;
}
.style-select {
  display: inline-block;
  position: relative;
  width: 100%;
  overflow: hidden;
  border: 1px solid $light-grey;
  background-color: $light-grey;
  color: #000;
  background-image: url(../images/arrow-down.svg);
  background-repeat: no-repeat;
  background-position: right 1.07692em center;
  border-radius: 4px;
  select {
    width: 120%;
    padding: 20px;
    font-size: 16px;
    -webkit-appearance: none;
    background-color: transparent;
    border: 0;
    outline: none;
  }
}

// icons
.icon-arrow-left {
  &:before {
    content: '<';
    vertical-align: middle;
    font-size: 150%;
  }
  &:hover {
    &:before {
      text-decoration: none;
    }
  }
}
.icon-tick:before {
  content: '\2714';
}
.icon-minus:before {
  content: '\2015';
}
