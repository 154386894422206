@import 'variables';
@import 'utils';
@import 'helpers';
@import 'login';
@import 'signup';
@import 'ula';
@import 'profile';
@import 'tooltips';
@import 'forms';
@import 'covers/covers';
@import 'trimble';
@import 'trial';
@import 'dots';
@import 'corona/index.scss';
@import 'enscape/index.scss';
@import 'axyz/index.scss';

* {
  padding: 0;
  margin: 0;
}

html,
body,
#root {
  height: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: $base-font-size;
}

.wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  position: fixed;
  .g-recaptcha {
    display: none;
  }
  .column {
    &.image-cover {
      background-size: cover;
      background-position: 50%;
      width: 40%;
      @include media($mobile) {
        display: none;
      }
      &:after {
        content: attr(data-copyright);
        color: $white;
        font-size: 10px;
        position: absolute;
        bottom: 10px;
        right: 61%;
      }

      .logo {
        position: absolute;
        font-size: 0px;
        top: 0px;
        left: 40%;
      }
      .link-full {
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
        &.no-content {
          color: transparent;
          opacity: 0;
        }
      }
      h5 {
        font-weight: $font-weight-bold;
        line-height: 1.4em;
      }
    }
    &.content {
      width: 60%;
      overflow: auto;
      padding-bottom: 50px;
      @include media($mobile) {
        width: 100%;
      }
      &:after {
        content: '';
        display: 'block';
        position: relative;
        clear: both;
      }
      .top-wrapper {
        display: flex;
        justify-content: space-between;
        @include bottom-offset(4);
        @include media($mobile) {
          padding-left: 10px;
          padding-right: 50px;
        }
        .mobile-logo {
          @include top-offset(3);
          display: none;
          float: left;
          @include media($mobile) {
            display: block;
            margin-top: 10px;
          }
          img {
            @include media($mobile) {
              max-width: 200px;
            }
          }
        }
        .button-actions {
          padding: 37px 95px;
          text-align: right;
          @include media($mobile) {
            float: right;
            padding: 35px 0;
            font-size: 90%;
          }
        }
      }
      #link-recover-account,
      #change-email {
        position: absolute;
        top: 0;
        right: 0;
      }
      .wrapper-loading {
        padding-top: 140px;
        h1 {
          text-align: center;
          @include bottom-offset(7);
        }
      }
      .wrapper-error {
        text-align: center;
        @include top-offset(15);
        .error {
          @include bottom-offset(2);
          @include top-offset(1);
        }
        h3 {
          color: $red;
        }
        svg {
          fill: $red;
        }
      }
    }
  }
  .loading {
    text-align: center;
    @include bottom-offset(7);
  }
  .wrapper-shrink {
    width: 450px;
    margin-right: auto;
    margin-left: auto;
    @include media($mobile) {
      width: 80%;
    }
    .title {
      text-align: center;
      font-size: 31px;
      color: $black;
      @include media($tablet) {
        font-size: 28px;
      }
    }
    .social-login-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      .row {
        @include top-offset(1);
      }
    }
    .login-button-wrapper {
      text-align: center;
      @include top-offset(6);
      @include bottom-offset(1);
      .button {
        font-size: 14px;
      }
      @include media($mobile) {
        padding: 0 10px;
      }
    }
  }
  &.link-account {
    .text-wrapper {
      text-align: center;
      @include bottom-offset(4);
    }
    .title {
      @include bottom-offset(2);
    }
    .subtitle {
      font-size: 17px;
      font-weight: $font-weight-regular;
      @include bottom-offset(2);
    }
    .email {
      font-weight: $font-weight-bold;
    }
    .link {
      font-weight: $font-weight-regular;
      @include top-offset(1);
    }
    .button-wrapper ~ p {
      text-align: center;
      @include top-offset(2);
      @include bottom-offset(2);
      .link {
        font-weight: $font-weight-regular;
      }
    }

    .button-wrapper {
      text-align: center;
    }
    p {
      text-align: center;
    }
  }
}
.alert {
  text-align: center;
  font-size: 1.2em;
  border: 1px dashed;
  padding: 20px 0;
  @include bottom-offset(5);
  margin-top: -30px;
}
.page-404 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url('../images/404-page.jpg');
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }

  h1 {
    position: absolute;
    display: inline-block;
    text-align: center;
    color: $white;
    width: 100%;
    top: 50%;
    margin-top: -50px;
  }
}

._loading_overlay_wrapper--active {
  z-index: 9 !important;
}

#msg-error {
  font-size: 17px;
  text-align: center;
  font-weight: $font-weight-regular;
  .icon-minus {
    padding: 7px 10px;
    color: $white;
    border-radius: 100px;
    margin-right: 10px;
    &::before {
      vertical-align: text-bottom;
    }
  }
}
