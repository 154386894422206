
#axyz {
  .wrapper {
    .column.image-cover {
      text-align: center;

      .logo.anima {
        position: static;
        margin: 80px auto 0;

        img {
          height: 70px;
        }
      }
    }
  }
}