@import 'variables';

.form-field-wrapper {
  position: relative;
  margin-bottom: 16px;

  &.has-error {
    input {
      border-color: $red;
    }
  }

  input#email[disabled] {
    color: #ababab;
    & ~ .field-icon.custom-icon {
      top: 36px;
    }
  }

  input#password {
    padding-right: 46px;
  }

  label {
    display: inline-block;
    margin-bottom: 4px;
    color: #555;
    .description {
      color: $grey;
    }
  }
  .password-show-hide-button {
    cursor: pointer;
    .password-visibility-icon {
      display: block;
      position: absolute;
      width: 24px;
      height: 24px;
      right: 16px;
      top: 40px;
      &.visible {
        background-image: url('../images/ic_visibility.svg');
      }
      &.off {
        background-image: url('../images/ic_visibility_off.svg');
      }
    }
    .password-visibility-text {
      display: block;
      position: absolute;
      right: 16px;
      bottom: 16px;
      color: $dark-grey;
    }
  }
  .field-icon {
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    right: -32px;
    top: 40px;
    &.valid-icon {
      background-image: url('../images/ic_check.svg');
    }
    &.custom-icon {
      width: 30px;
      height: 30px;
      right: 6px;
      top: 33px;
    }
  }
}

.form-error {
  text-align: center;
  @include bottom-offset(5);
  h3 {
    font-size: 2em;
  }
  h5 {
    font-size: 1.2em;
  }
  svg {
    fill: $red;
  }
  #msg-error {
    &.error {
      @include bottom-offset(2);
      @include top-offset(1);
    }
  }
}
