@import 'variables';

$trimble-main: #005f9e;

.wrapper {
  &.wrapper-trimble-login {
    .column.content {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
    }
    .trimble-login-content {
      min-width: 225px;
      width: 468px;
      padding-top: 50px;
      @include media($mobile) {
        width: 90%;
      }
      .title {
        font-size: 34px;
        font-style: normal;
        line-height: 24px;
        @include bottom-offset(3);
        @include media($mobile) {
          line-height: 32px;
        }
      }
      .subtitle {
        @include bottom-offset(4);
        @include media($mobile) {
          line-height: 20px;
        }
      }
      .login-hint {
        width: 325px;
        font-size: 14px;
        font-weight: $font-weight-regular;
        padding: 0 40px;
        @include bottom-offset(6);
        @include media($mobile) {
          width: 90%;
        }
      }
    }
    // do not show CHAOSGROUP logo (part of top menu) in mobile view;
    .column.content .top-wrapper {
      display: none;
    }
  }
  &.signup-complete.sketchup {
    .title {
      font-size: 31px;
      @include bottom-offset(3);
    }
    .subtitle {
      @include bottom-offset(6);
    }
    .hr {
      @include top-offset(4);
      @include bottom-offset(5);
    }
    .name {
      font-size: 17px;
      @include top-offset(4);
    }
    .button {
      @include bottom-offset(3);
    }
    .activation-status-running ~ dark-gray {
      @include bottom-offset(3);
    }
    [class^='activation-status-'],
    [class*=' activation-status-'] {
      font-size: 18px;
      font-style: normal;
      font-weight: $font-weight-bold;
      line-height: 16px;
      &.activation-status-running {
        color: $trimble-main;
        @include bottom-offset(3);
      }
      &.activation-status-failed {
        color: $red;
        @include bottom-offset(4);
      }
    }
  }
  .btn-ls-prompt {
    animation: color-change 1s;
    border: 1px solid $trimble-main;
    background-color: $white;
    color: $trimble-main;
    font-size: 18px;
    line-height: 18px;
    font-weight: $font-weight-regular;
    padding: 12px 24px;
    &:hover {
      color: $white;
      background-color: $trimble-main;
    }
  }
}
