.wrapper.ula {
  .column.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .top-wrapper {
      margin-bottom: 200px;
    }
    .title {
      font-size: 30px;
      margin-top: 33px;
      text-align: center;
    }
    .description {
      max-width: 300px;
      margin: 30px 0;
      text-align: center;
    }
    .login-button-wrapper {
      margin-top: 10px;
      margin-bottom: 15px;
      text-align: center;
      &::after {
        content: "";
        display: block;
        position: relative;
        background-color: #ededed;
        height: 1px;
        width: 450px;
        margin-top: 40px;
      }
    }
    .info {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }

  .signin-info {
    padding-bottom: 1em;
  }

  .button {
    padding: .5em 2.5em;

    &.muted {
      margin: 2em 0 0 0;
      border: 2px solid $black;
      color: $black;

      &:hover {
        background-color: $black;
        color: $white;
      }
    }
  }

  &.err {
    .column.content > .title {
      color: $red;
    }
  }
}
