@import 'variables';

#enscape {
  * {
    font-family: 'Poppins';
  }

  .top-wrapper {
    margin-bottom: 0;
    .button-actions {
      padding-right: 20px;
    }
  }

  .wrapper-shrink {
    margin-top: 40px;
    max-width: 450px;
  }

  .image-cover {
    min-width: 430px;
  }

  .divider {
    margin-top: 57px;
    margin-bottom: 27px;
    font-size: 15px;
    max-width: 330px;
    &:before {
      left: 0;
      right: 70%;
    }
    &:after {
      right: 0;
      left: 70%;
    }
  }

  a,
  .link {
    text-decoration: none;
  }

  #btn-google-login > div,
  .corporate-login-button-wrapper {
    width: 100% !important;
    max-width: 330px !important;
    height: 48px;
    border-color: $disabled-silver;
    margin-left: auto;
    margin-right: auto;
    border-color: $blue-gray;
    &:hover {
      border-color: $orange-secondary !important;
    }
  }

  .corporate-login-button-wrapper {
    .corporate-login-button {
      span {
        line-height: 3rem;
        font-size: 15px;
        color: $black;
      }
      img {
        left: -6px;
        content: url(./enscape-corporate-login-button-icon.svg);
      }
    }
  }

  .social-login-buttons {
    .row {
      margin: 0;
      width: 100%;
      & > span {
        width: 100%;
      }
      #btn-google-login > div {
        span {
          margin: 0;
          font-size: 15px !important;
          color: $black !important;
        }
        img {
          left: -23px;
          width: 19px;
        }
      }
    }
  }

  .login-button-wrapper {
    margin-top: 36px;
  }

  .back {
    font-size: 15px;
  }

  .wrapper.corporate-login {
    .button-wrapper {
      margin-top: 35px;
    }
  }

  .profile {
    &.recover {
      .subtitle {
        color: #1a1a1a;
        margin-bottom: 48px;
        br {
          display: none;
        }
      }
      .signup-confirmation {
        font-size: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        a {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .button-wrapper {
        margin-top: 35px;
      }
    }
    &.password-reset {
      .wrapper-shrink {
        &.error {
          .button {
            line-height: 40px;
          }
        }
      }
      .title {
        margin-bottom: 5px;
      }
      .subtitle {
        line-height: 28px;
        padding: 0 50px;
        font-size: 15px;
      }
    }
    &.activate {
      .wrapper-shrink {
        text-align: center;

        .title {
          font-family: 'Poppins';
          margin: 32px 0;
        }
        .subtitle {
          margin-bottom: 32px;
          color: $black;
        }
        .divider {
          margin-bottom: 15px;
          margin-top: 92px;
          &:before {
            right: 0;
          }
        }
        p {
          padding-top: 15px;
          margin-bottom: 32px;
          font-size: 15px;
          line-height: 24px;
        }
      }
    }
  }

  #form-register {
    .button-pre-text {
      margin-top: 0;
      margin-bottom: 34px;
      font-size: 15px;
      text-align: left;
    }
    .subtitle {
      font-size: 15px;
      color: #1a1a1a;
    }
  }

  .reg-complete {
    .content {
      .title {
        max-width: 460px;
        margin: 0 auto;
      }
      .subtitle {
        margin-top: 8px;
        color: #1a1a1a;
        margin-bottom: 48px;
      }
      .signup-confirmation {
        max-width: 450px;
        margin: 0 auto;
      }
      #msg-register-success {
        line-height: 25px;
        font-size: 15px;
        max-width: 460px;
        margin: 0 auto;
        .button {
          margin-top: 45px;
        }
        .divider {
          margin-bottom: 15px;
          margin-top: 128px;
          &:before {
            right: 0;
          }
        }
        .bottom-text {
          padding-top: 15px;
          line-height: 33px;
        }
      }
      .top-wrapper {
        margin-bottom: 120px;
      }
    }
  }

  .back {
    .icon-arrow-left:before {
      font-size: 100%;
      vertical-align: initial;
    }
  }
}

@keyframes slide {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(5px, 0);
  }
}
