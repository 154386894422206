// Main Colors
$white: #fff;
$black: #1c1c1c;
$blue: #0db5ff;
$dark-blue: #0984ba;
$red: #e1161c;
$dark-red: #bb2d2d;
$darker-red: #a32626;
$grey: #aaaab3;
$light-grey: #edeef0;
$dark-grey: #555555;
$green: #21ad2e;
$light-green: #77b22a;

// Breakpoints
$mobile: new-breakpoint(max-width 960px);
$tablet: new-breakpoint(max-width 1200px);
$small-laptop: new-breakpoint(max-width 1300px);
$standard-desktop: new-breakpoint(max-width 1440px);

// Font
$base-font: "Poppins", sans-serif;
$base-font-size: 13px;
$font-weight-regular: 400;
$font-weight-bold: 700;
