@import 'variables';

.wrapper {
  &.login {
    .title {
      @include bottom-offset(1);
    }
    .button-post-text {
      text-align: center;
      @include bottom-offset(4);
    }

    .activation-resend-success {
      @include top-offset(2);
      @include bottom-offset(4);
      line-height: 24px;
      .icon-tick {
        font-weight: $font-weight-bold;
        color: $white;
        border-radius: 100%;
        padding: 4px;
        margin-right: 4px;
        background-color: $green;
      }
    }

    .password-info-svg {
      position: absolute;
      left: -130px;
      top: 35px;
      padding-left: 14px;
      padding-right: 20px;
      padding-bottom: 5px;
      padding-top: 5px;
      background-image: url(../images/pad.svg);
      background-size: contain;
      background-repeat: no-repeat;
      color: $white;
      font-size: $base-font-size;
      text-shadow: 0.5px 0.9px 0 rgba(43, 17, 17, 0.1);
    }

    #link-recover-account {
      font-size: 95%;
    }

    h5,
    h6 {
      color: #525252;
    }

    &.discourse {
      .wrapper-shrink {
        div {
          text-align: center;
          .title {
            @include bottom-offset(7);
          }
        }
      }
    }
  }

  &.login-success {
    .column.content {
      background-color: white !important;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      .top-wrapper {
        align-self: start;
        margin-bottom: 20px;
      }
      img.success {
        width: 35px;
      }
      .title {
        font-weight: 600;
        font-size: 36px;
        margin: 50px 0;
      }
      .signed-in-as {
        font-weight: 400;
        font-size: 15px;
        display: flex;
        flex-direction: column;
        margin-bottom: 80px;
      }
      .info {
        font-weight: 700;
        font-size: 15px;
      }
    }
  }

  &.corporate-login {
    .form-field-wrapper {
      height: 100px;
    }
    .title {
      @include bottom-offset(6);
    }
    .back {
      text-align: center;
      @include top-offset(3);
    }
    .button-wrapper {
      text-align: center;
      button {
        width: 220px;
      }
    }
  }
}

.corporate-login-button-wrapper {
  display: flex;
  align-items: center;
  background-color: white;
  justify-content: center;
  border: 1px solid #eee;
  border-radius: 100px;
  cursor: pointer;
  width: 286px;
  height: 46px;
  margin: auto;
  transition: all 0.218s ease 0s;
  @include top-offset(1);
  &:hover {
    border-color: $blue;
  }
  .corporate-login-button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.218s ease 0s;
    color: #525252;
    img {
      width: 18px;
      height: 20px;
      position: relative;
      left: -18px;
    }
  }
}

.trimble-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.218s ease 0s;
  width: 286px;
  height: 46px;
  border-radius: 100px;
  &.trimble-light {
    border: 1px solid #eee;
    color: #525252;
    &:hover {
      border: 1px solid $blue;
    }
  }
  &.trimble-dark {
    background-color: #005f9e;
    color: #fff;
    &:hover {
      background-color: #145b8d;
    }
  }
  img {
    position: relative;
    left: -45px;
    width: 25px;
    height: 25px;
  }
  span {
    display: inline-block;
    margin-left: -20px;
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    font-size: 1rem;
    line-height: 3em;
    vertical-align: top;
  }
}

#btn-facebook-login div {
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-color: white !important;
  width: 286px !important;
  height: 46px;
  border-radius: 100px;
  transition: all 0.218s ease 0s !important;
  box-shadow: none !important;
  border: 1px solid #eee;
  &:hover {
    border-color: $blue !important;
  }
  span {
    color: #525252 !important;
    font-size: 1rem !important;
    padding: 0;
    margin: 0;
  }
  img {
    display: none;
  }
}

#btn-google-login div {
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-color: white !important;
  width: 286px !important;
  height: 46px;
  border-radius: 100px;
  transition: all 0.218s ease 0s !important;
  box-shadow: none !important;
  border: 1px solid #eee;
  &:hover {
    border-color: $blue !important;
  }
  span {
    color: #525252 !important;
    font-size: 1rem !important;
    padding: 0 !important;
    margin: 0;
    margin-left: -18px !important;
  }
  img {
    margin: 0 !important;
    position: relative;
    left: -53px;
  }
}
