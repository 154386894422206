@import '../variables'

#total-chaos-2019-cover
  .header
    h5
      text-transform: uppercase
  .button-wrapper
    .date
      color: #fec98a
      font-size: 26px
      @include media($standard-desktop)
        font-size: 22px
      @include media($small-laptop)
        font-size: 20px

#total-chaos-2020-cover
  .header
    h5
      text-transform: uppercase
      color: $black
      padding-right: 17%
      padding-left: 17%
  .buttons
    height: 60%
    .button-wrapper
      .tickets
        color: $white
        font-size: 26px
        padding-top: 2%
        padding-right: 19%
        padding-left: 19%
        padding-bottom: 2%
        @include media($standard-desktop)
          font-size: 22px
        @include media($small-laptop)
          font-size: 20px
      .button
        padding: 15px 30px
        font-size: 16px
        line-height: 16px
        font-weight: $font-weight-bold
