.wrapper-trimble-login
  #trimble-cover
    .cover-logo
      text-align: start
      img
        max-width: 400px
        margin-top: 16px
        margin-left: 39px
        height: 77.5px
        width: 237.5px
    &.column.image-cover:after
      font-size: 11px
