@import './total-chaos'
@import './chaos-cloud'
@import './trimble'

.wrapper.login, .wrapper.ula
  .flex-grid
    display: flex
    flex-direction: column
    height: 100%
    align-items: center
    text-align: center
    .col
      flex: 1
    .buttons
      margin-top: 10px
      height: 55%
      text-align: center
      padding: 0 20px
      @include media($standard-desktop)
        padding: 0 10px
      @include media($small-laptop)
        padding: 0 10px
    .cover-logo
      flex-direction: row
      align-items: flex-end
      height: 45%
      a
        display: inline-block
      img
        max-width: 200px
        @include bottom-offset(2)
    .header
      h5
        color: $white
        font-size: 31px
        @include media($standard-desktop)
          font-size: 31px
        @include media($small-laptop)
          font-size: 26px
    .button-wrapper
      .button
        font-size: $base-font-size
        @include top-offset(2)
