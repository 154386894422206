.trial
  &.login
    .title
      margin: 0
    .subtitle
      font-size: 17px
      text-align: center
      padding-bottom: 30px
    .privacy-text
      text-align: center
      font-size: 13px
      color: #525252
      max-width: 330px
      margin: auto
      padding-bottom: 0
    .form-field-wrapper
      margin-bottom: 30px
  &.signup
    .form-field-wrapper
      margin-bottom: 20px
    .title
      margin-bottom: 30px
    .style-select > select
      height: 46px
      font-size: 13px
      padding: 10px
      padding-left: 17px
    .bottom-content
      margin-top: 30px !important
  &.success
    .subtitle
      font-size: 17px
      text-align: center
      padding-bottom: 30px
      padding-top: 10px
    .success-content
      text-align: center
      gap: 30px
      display: flex
      flex-direction: column
      margin: 30px 0
      img
        height: 42px
      .title, .description
        font-size: 17px
      .divider
        width: 100%
  &.confirm
    .subtitle
      font-size: 17px
      text-align: center
    .confirm-content
      text-align: center
      .privacy-text
        padding: 0
        color: #525252
        max-width: 330px
        margin: auto auto 30px
      .user-data
        margin: 45px 0
        font-size: 17px
        p
          padding: 0
  &.recover
    .signup-confirmation
      text-align: center
      margin-top: 50px
      .button
        padding: 13px 0
        margin-top: 20px
  &.error
    .wrapper-shrink
      text-align: center
    .title
      color: $red !important
    .error-content
      color: $black
      font-size: 17px
    .button
      width: 185px
      padding: 13px 30px

  #email[disabled]
    color: #ABABAB
  .login-button-wrapper
    margin-top: 30px !important
  .button
    width: 100%
    &[disabled]
      background-color: $grey
      border-color: $grey
      cursor: default
  .divider
    margin-top: 30px
    margin-bottom: 10px
    &::before
      right: 55%
    &::after
      left: 55%
  .send-again
    font-size: 14.5px
    max-width: 370px
    margin: 0 auto
    a
      cursor: pointer

  input
    height: 46px
    font-size: 13px
  .password-visibility-icon
    top: 36px !important

.trial-products-strip
  display: flex
  flex-direction: column
  width: 40px
  position: absolute
  right: 15px
  bottom: 80px
  gap: 6px
