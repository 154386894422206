@import "variables";

#corona {
  .button {
    border-radius: 12px;
    text-decoration: none;
    color: white;

    &.btn-active {
      background-color: $corona-orange;
      border-color: $corona-orange;
      &:hover {
        color: $corona-lightgrey;
      }
    }
  }

  .link-nav-back {
    text-decoration: none !important;
    &:hover {
      .text,
      .icon-arrow-left {
        color: $corona-grey;
      }
    }
    .text,
    .icon-arrow-left {
      color: $corona-black;
    }
    .text {
      text-decoration: underline;
    }
  }

  .corporate-login-button-wrapper {
    border-radius: 12px;
    &:hover {
      border-color: $corona-mango;
    }
  }

  .logo.corona {
    top: 22px;
    left: 41%;
    img {
      height: 50px;
    }
  }

  a, .link {
    color: $corona-black;
    text-decoration: underline;
    &:hover {
      color: $corona-grey;
    }
  }

  input {
    &:hover,
    &:focus {
      border-color: $corona-mango !important;
    }
  }
}
