@function new-breakpoint($query) {
  @return $query;
}

@mixin media($query) {
  @media screen and (nth($query, 1): nth($query, 2)) {
    @content;
  }
}

@mixin top-padding($count: 1) {
  padding-top: 10px * $count;
}
@mixin bottom-padding($count: 1) {
  padding-bottom: 10px * $count;
}
@mixin top-offset($count: 1) {
  margin-top: 10px * $count;
}
@mixin bottom-offset($count: 1) {
  margin-bottom: 10px * $count;
}
