@import '../variables'

#chaos-cosmos-cover,
#chaos-cloud-cover,
.header
  h5
    font-size: 31px
    max-width: 70%
    margin: 10px auto
  .cover-logo
    img
      max-width: 250px
  .buttons
    height: 60%
    .button-wrapper
      .button
        padding: 12px 35px
        line-height: 16px
