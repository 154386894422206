@import "variables";

.wrapper.signup,
.wrapper.profile,
.wrapper.profile.delete,
.wrapper.profile.activate,
.wrapper.corporate-login {
  .title {
    text-align: center;
    color: $black;
  }
  .subtitle {
    text-align: center;
    font-size: 17px;
    font-weight: $font-weight-regular;
    @include bottom-offset(5);
  }
  .button-wrapper {
    text-align: center;
  }
  .privacy-text {
    text-align: center;
    @include top-offset(4);
  }
  .cg-captcha {
    margin: 0 auto;
    width: 260px;
    text-align: left;
    @include top-offset(4);
    @include bottom-offset(4);
    &.loading {
      .btn-refresh {
        -webkit-animation: spin 1s linear infinite;
        -moz-animation: spin 1s linear infinite;
        animation: spin 1s linear infinite;
      }
    }
    img {
      display: block;
      width: 180px;
    }
    .image-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1em;
    }
    .btn-refresh {
      width: 24px;
      height: 20px;
      margin-right: 1.5em;
      border: none;
      background-color: transparent;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJmZWF0aGVyIGZlYXRoZXItcmVmcmVzaC1jdyI+PHBvbHlsaW5lIHBvaW50cz0iMjMgNCAyMyAxMCAxNyAxMCI+PC9wb2x5bGluZT48cG9seWxpbmUgcG9pbnRzPSIxIDIwIDEgMTQgNyAxNCI+PC9wb2x5bGluZT48cGF0aCBkPSJNMy41MSA5YTkgOSAwIDAgMSAxNC44NS0zLjM2TDIzIDEwTTEgMTRsNC42NCA0LjM2QTkgOSAwIDAgMCAyMC40OSAxNSI+PC9wYXRoPjwvc3ZnPg==");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
      cursor: pointer;
      outline: 0;
      &:hover {
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjIwcHgiIHZpZXdCb3g9IjAgMCAyNCAyMCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5yZWZyZXNoLWJsYWNrPC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxkZWZzPjwvZGVmcz4gICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj4gICAgICAgIDxnIGlkPSJyZWZyZXNoLWJsYWNrIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxLjAwMDAwMCwgMS4wMDAwMDApIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZT0iIzBEQjVGRiI+ICAgICAgICAgICAgPHBvbHlsaW5lIGlkPSJTaGFwZSIgcG9pbnRzPSIyMiAxIDIyIDcgMTYgNyI+PC9wb2x5bGluZT4gICAgICAgICAgICA8cG9seWxpbmUgaWQ9IlNoYXBlIiBwb2ludHM9IjAgMTcgMCAxMSA2IDExIj48L3BvbHlsaW5lPiAgICAgICAgICAgIDxwYXRoIGQ9Ik0yLjUxLDYgQzMuNTQwODgxNjQsMy4wODY4MTE2NSA1Ljk5NDI2NzE3LDAuOTA2MjE4MjQ1IDkuMDA4Mjg2NzIsMC4yMjQyNTgyNjUgQzEyLjAyMjMwNjMsLTAuNDU3NzAxNzE1IDE1LjE3NTQ0MDgsMC40NTQzNDU3MzMgMTcuMzYsMi42NCBMMjIsNyBNMCwxMSBMNC42NCwxNS4zNiBDNi44MjQ1NTkxNiwxNy41NDU2NTQzIDkuOTc3NjkzNzMsMTguNDU3NzAxNyAxMi45OTE3MTMzLDE3Ljc3NTc0MTcgQzE2LjAwNTczMjgsMTcuMDkzNzgxOCAxOC40NTkxMTg0LDE0LjkxMzE4ODMgMTkuNDksMTIiIGlkPSJTaGFwZSI+PC9wYXRoPiAgICAgICAgPC9nPiAgICA8L2c+PC9zdmc+");
      }
    }
  }
  .captcha-error {
    text-align: center;
    color: $red;
  }
  .column {
    &.content.content-centered {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
    .content-centered-inner {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  h5,
  h6 {
    color: #525252;
  }
}
.wrapper {
  &.signup {
    .button-pre-text {
      text-align: center;
      @include top-offset(4);
    }
    .button-post-text {
      text-align: center;
      @include top-offset(.5);
      @include bottom-offset(4);
    }
    .buttons-wrapper {
      text-align: center;
      .button-wrapper {
        @include top-offset(1);
      }
      .back {
        text-align: center;
        padding: 0;
      }
    }
    .checkbox-style {
      padding-left: 40px;
      margin-top: 4px;
      input {
        display: none;
      }
      input:checked + .label-checkbox:after {
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgMjAgMjAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDIwIDIwOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6bm9uZTtzdHJva2U6IzFDMUMxQzt9Cgkuc3Qxe2ZpbGw6IzFDMUMxQzt9Cjwvc3R5bGU+CjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik00LDAuNWgxMmMxLjksMCwzLjUsMS42LDMuNSwzLjV2MTJjMCwxLjktMS42LDMuNS0zLjUsMy41SDRjLTEuOSwwLTMuNS0xLjYtMy41LTMuNVY0QzAuNSwyLjEsMi4xLDAuNSw0LDAuNXoiCgkvPgo8cGF0aCBjbGFzcz0ic3QxIiBkPSJNMTQuMiw1LjZsLTUuOSw1LjlMNS44LDguOWwtMS41LDEuNWwyLjUsMi41bDEuNSwxLjVsMS41LTEuNUwxNS43LDdMMTQuMiw1LjZ6Ii8+Cjwvc3ZnPg==");
      }
      .label-checkbox {
        cursor: pointer;
        &::after {
          display: block;
          position: absolute;
          left: 0;
          top: 0.5em;
          width: 1.69231em;
          height: 1.69231em;
          content: "";
          background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMCAyMCI+PHBhdGggZD0iTTQgLjVoMTJjMS45IDAgMy41IDEuNiAzLjUgMy41djEyYzAgMS45LTEuNiAzLjUtMy41IDMuNUg0QzIuMSAxOS41LjUgMTcuOS41IDE2VjRDLjUgMi4xIDIuMS41IDQgLjV6IiBmaWxsPSJub25lIiBzdHJva2U9IiMxYzFjMWMiLz48L3N2Zz4=");
          background-position: 50%;
          background-repeat: no-repeat;
        }
        .label-text {
          font-size: 13px;
          color: $black;
        }
      }
    }
    .signup-confirmation {
      font-size: 14px;
      margin: 0 50px;
      text-align: center;
    }
    .reg-complete {
      .title {
        font-size: 30px;
        @include top-offset(6);
      }
    }
    &.google {
      .text-wrapper {
        text-align: center;
        @include bottom-offset(4);
        .subtitle {
          @include bottom-offset(5);
        }
      }
      .name {
        font-size: 17px;
      }
      .email {
        padding: 0
      }
      .bottom-content {
        text-align: center;
        @include top-offset(4);
        .button-wrapper {
          @include top-offset(1);
        }
      }
      .complete {
        text-align: center;
        .title {
          font-size: 28px;
          @include bottom-offset(1);
        }
        .subtitle {
          @include bottom-offset(4);
        }
        .icon-tick {
          font-weight: $font-weight-bold;
          color: $white;
          border-radius: 100%;
          padding: 6px 12px;
          font-size: 20px;
          background-color: $blue;
        }
        .name {
          @include top-offset(2);
        }
        .button {
          @include top-offset(3);
        }
      }
    }
  }
  &.delete {
    @include bottom-offset(3);
    .error {
      text-align: center;
      h3 {
        font-size: 28px;
        @include top-offset(2);
      }
      h5 {
        font-size: 17px;
        font-weight: $font-weight-regular;
        @include top-offset(1);
        @include bottom-offset(2);
      }
      #msg-error {
        font-size: $base-font-size;
        color: $black;
      }
    }
    .hr {
      @include top-offset(8);
      @include bottom-offset(4);
    }
    .confirm {
      @include bottom-offset(3);
    }
    .support {
      text-align: center;
      h6 {
        font-size: 16px;
        @include bottom-offset(1);
      }
    }
  }
  .success {
    text-align: center;
    h1 {
      @include top-offset(6);
      @include bottom-offset(2);
    }
  }
  #form-delete-account {
    h3 {
      @include bottom-offset(3);
    }
    h5 {
      text-align: center;
      font-size: 17px;
      @include bottom-offset(3);
    }
    .button-wrapper {
      @include top-offset(1);
      @include bottom-offset(10);
    }
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
