$dot-dark: $dark-grey;
$dot-mid: #979797;
$dot-light: #c4c4c4;

.loading-dots {
  font-size: 36px;
  &::after {
    content: ".";
    animation: dots 1s steps(3, end) infinite;
  }
}

@keyframes dots {
  0% {
    color: $dot-dark;
    text-shadow: 0.25em 0 0 $dot-light, 0.5em 0 0 $dot-mid;
  }
  50% {
    color: $dot-mid;
    text-shadow: 0.25em 0 0 $dot-dark, 0.5em 0 0 $dot-light;
  }
  100% {
    color: $dot-light;
    text-shadow: 0.25em 0 0 $dot-mid, 0.5em 0 0 $dot-dark;
  }
}
