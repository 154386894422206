@import "variables";

.profile {
  .wrapper-shrink {
    @include top-offset(10);
  }
  #form-change-password {
    @include top-offset(7);
    .button-wrapper {
      text-align: center;
      @include top-offset(1);
    }
  }
  &.activate,
  &.password-reset {
    .wrapper-shrink {
      width: 90%;
      span[class^="icon-"] {
        font-weight: $font-weight-bold;
        color: $white;
        border-radius: 100%;
        padding: 7px;
        margin-right: 0.5rem;
        flex: 0 0 20px;
        height: 20px;
        text-align: center;
      }
      .icon-tick {
        background-color: $green;
      }
      .icon-minus {
        background-color: $red;
      }
      .info {
        color: $red;
        text-align: center;
        @include top-offset(-2);
        @include bottom-offset(4);
      }
      &.error {
        .error-wrapper {
          display: flex;
          max-width: 50rem;
          margin: 0 auto;
          h5 {
            font-size: 17px;
            font-weight: $font-weight-regular;
            text-align: center;
          }
        }
      }
    }
  }
  &.password-reset {
    .wrapper-shrink {
      &.error {
        text-align: left;
        .button-wrapper {
          text-align: center;
          .button {
            @include top-offset(4);
          }
        }
      }
      .title {
        @include bottom-offset(6);
        @include top-offset(6);
      }
      .button-wrapper {
        @include bottom-offset(3);
        @include top-offset(1);
      }
      form {
        max-width: 450px;
        margin: 0 auto;
      }
    }
  }
  &.activate {
    .wrapper-shrink {
      @include top-offset(8);
      .button-wrapper {
        text-align: center;
      }
    }
  }
  &.recover {
    .title {
      text-align: center;
      @include bottom-offset(2);
    }
    .subtitle {
      text-align: center;
      font-weight: $font-weight-regular;
      font-size: 17px;
      line-height: 1.4em;
      @include bottom-offset(7);
    }
    .button-wrapper {
      text-align: center;
      @include top-offset(7);
      @include bottom-offset(2);
    }
    .success {
      .title {
        @include top-offset(6);
        @include bottom-offset(7);
      }
      .signup-confirmation {
        text-align: center;
        font-size: 14px;
        @include top-offset(6);
      }
      #msg-reset-password {
        @include bottom-offset(6);
      }
    }
  }
  &.password-setup {
    .title {
      @include top-offset(6);
      @include bottom-offset(6);
    }
    .button-wrapper {
      text-align: center;
      @include top-offset(1);
      @include bottom-offset(2);
    }
    .wrapper-shrink {
      &.error {
        text-align: left;
        .button-wrapper {
          text-align: center;
          .button {
            @include top-offset(4);
          }
        }
      }
    }
  }
  &.password-change {
    .info {
      color: $red;
      text-align: center;
      min-width: 37rem;
      @include top-offset(5);
      @include bottom-offset(-5);
    }
    .success-message {
      @include top-offset(5);
    }
  }
  &.edit {
    .wrapper-shrink {
      @include top-offset(0);
      @include bottom-offset(0);
      .title {
        @include bottom-offset(7);
      }
      .button-wrapper {
        text-align: center;
        @include top-offset(1);
      }
      .form-field-wrapper {
        @include bottom-offset(2);
      }
      .profile-updated {
        @include bottom-offset(5);
        span {
          @include top-offset(1);
        }
      }
      .change-password {
        @include bottom-offset(2);
      }
      hr {
        @include top-offset(1);
        @include bottom-offset(1);
      }
    }
  }
}
